@import "/assets/plugins/bootstrap/css/bootstrap.min.css";
@import "/assets/plugins/select2/dist/css/select2.min.css";
@import "/assets/css/style.css";
@import "/assets/css/custom.css";
@import "/assets/css/colors/blue.css";
#loaderRoot {
  display: block;
}

.loaderPositionsStyle {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: auto;
}

.loaderPositionsStyleSub {
  margin: auto;
  max-height: 100%;
}

body [aria-labelledby*="-title"] {
  display: none !important;
}

.cat {
  text-transform: capitalize !important;
}

.name-upload {
  width: 20% !important;
  display: inline !important;
}

.time-input {
  width: 50px !important;
  padding: 5px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.tbl-button,
.tbl-button:hover,
.tbl-button:active,
.tbl-button:focus,
.tbl-button:visited,
.tbl-button:focus-within {
  background-color: #7460ee !important;
  border-radius: 1 !important;
  border: none !important;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.4) !important;
}

.tbl-head {
  font-size: 18px;
  color: #7460ee !important;
  font-weight: 500;
}

.custom-file-upload {
  background-color: #7460ee !important;
  border: 1px solid #7460ee;
  border-radius: 5px !important;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  color: #fff;
}

.search-icon {
  position: relative;
  bottom: 32px;
  font-size: 20px;
  right: -88%;
}

.line-background {
  background-color: #7460ee !important;
  color: #fff;
  padding: 10px 10px;
}

.icon-clr {
  font-size: 18px;
  color: #7460ee;
}

.card-bg-color {
  background-color: #f4f4f9;
}

/* .image-parent{
    position: relative;
} */

/* .image-parent {
  width: 10em;
    height: 12em;
    justify-content: center;
    align-items: center;
} */

.cardRatio {
  height: 12em;
}

.file-image {
  display: block;
  width: 100%;
  height: auto;
}

.image-parent:hover .middle {
  opacity: 1;
  background-color: #000;
}

.deleteIcon {
  /* display: none;
  position: absolute;
  right: 5px;
  top: 5px;
  color: #fff; */
  color: white;
  font-size: 20px;
  position: absolute;
  right: 0px;
  top: 15px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.middle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: rgba(116, 96, 238, 0.2);
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.titlestrip_appointment {
  background-color: #5955a5 !important;
  text-align: center;
  color: #ffffff !important;
  margin-bottom: 25px;
}

.view {
  color: black;
  font-size: large;
  padding: 2px;
}

.status {
  text-align: center;
}

.space_between {
  justify-content: space-between;
}

.full_width {
  width: 100%;
}

.myappointment_modal {
  border-style: dotted;
  border-radius: 6px;
  margin-bottom: 7px;
  padding: 8px;
}

.blink_me {
  /* animation: blinker 1s linear infinite; */
  width: 70%;
}

/* @keyframes blinker {
  80% {
    opacity: 0;
  }
} */

.event .panel-body {
  background: cadetblue;
  border: 1px solid #448aff;
  padding: 0;
  margin: 2%;
  border-radius: 4px;
  /* height:86px; */
}
.event .panel-body > div {
  padding: 10px 10px;
}
.event .panel-body .rsvp {
  border-left: none;
  padding: 0;
  text-align: center;
  position: relative;
  background: #558e90;
  color: white;
  /* height: 100%; */
}

.event .rsvp > div {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  /* background: blue; */
  width: 100%;
}

.event .rsvp i {
  font-size: 2em;
  display: block;
  position: relative;
  top: 17%;
  margin-bottom: 5px;
}
.event .rsvp i:last-of-type {
  font-size: 1.5em;
  margin-top: -23px;
}

.event .rsvp span {
  cursor: pointer;
  padding: 0 5px;
  margin: 5px 0;
  width: 45%;
}
/* .event .rsvp span:first-of-type{
  border-right: 1px solid white;
} */
.event .rsvp span:hover {
  color: #1565c0;
}

.event .info {
  color: white;
  font-size: 28px;
}

.event .info > div,
.event .info > ul {
  font-size: 12px;
}

.event .author .profile-image {
  position: absolute;
  background: white;
  padding: 3px;
  width: 5em;
  border-radius: 50%;
  border: 1px solid #ccc;
  box-sizing: content-box;
  z-index: 1;
  top: 4px;
}

.author img {
  width: 121%;
  border-radius: 50%;
}

/* Overwrites */
.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs a {
  background: #90caf9;
  color: white !important;
}
.info .nav-tabs li.active a {
  background-color: crimson;
  color: white !important;
}
.info .nav-tabs li:hover a,
.info .nav-tabs li.active:hover a {
  background: rgb(24, 18, 18) !important;
  color: white !important;
}
.tab-content {
  background: white;
  border-radius: 0 10px;
  padding: 10px;
}
.register {
  background-color: crimson;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .event .panel-body {
    /* height:158px; */
  }

  .event .rsvp {
    min-height: 100%;
  }

  .event .rsvp i {
    font-size: 4em;
    top: 0%;
  }

  .event .panel-body .author {
    position: relative;
    color: crimson;
    padding: 10px;
    height: 100%;
  }
  .event .profile {
    position: relative;
    z-index: 0;
    border-left: 2px solid white;
    top: -5px;
    padding-left: 55px;
    height: 100%;
    left: 10%;
  }
  .event .profile strong {
    display: block;
    color: white;
    font: 20px bold "Fredoka One", serif;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #448aff;
    margin-bottom: 5px;
  }
  .event .author .profile i {
    color: #448aff;
    padding-left: 0;
  }
  .event .profile .links {
    position: absolute;
    bottom: 0;
  }
  .event .profile article {
    padding: 0 41px 0 0;
  }
  .event .author .profile i:hover {
    color: #00bcd4;
  }
  .event .author .profile-image {
    position: absolute;
    background: white;
    padding: 3px;
    width: 30%;
    border-radius: 50%;
    border: 1px solid #ccc;
    box-sizing: content-box;
    z-index: 1;
    top: 22px;
  }
  .event .author img {
    width: 100%;
    border-radius: 50%;
  }
}

.cursor_pointer {
  cursor: pointer;
}

.visible-xs {
  margin-right: 2%;
}
.event_time {
  margin-bottom: 1%;
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .event .profile {
    left: 17%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.space-around {
  justify-content: space-around;
}

.event_name {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-left: 5px;
}

.setheight {
  height: 125px;
}

.datetimebox {
  text-align: center;
  background: white;
  border: 1px solid;
  margin: 0px 48px;
  margin-top: -22px;
  font-size: 12px;
}

.description_title {
  color: #4a4a4a;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.description {
  width: 100%;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  height: 100px;
  white-space: break-spaces;
  overflow: auto;
}

.event_location {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  color: #9b9b9b;
  display: inline-block;
  width: 100%;
}

.register_btn {
  border: 1px solid #0c5397;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 7px 40px;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  background: 0 0;
  text-transform: uppercase;
  color: #12589a;
  font-size: 11px;
  font-weight: 600;
  border-radius: 0px;
}

.tabItems {
  margin-right: 25px;
  font-weight: 600;
  font-size: 18px;
  color: #404040;
  background: transparent;
  padding: 0px 20px;
}

.tabItems:hover {
  color: none;
}

.animate {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.info-card {
  width: 100%;
  border: 1px solid rgb(215, 215, 215);
  position: relative;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
  overflow: hidden;
}
/* .info-card > img {
  width: 100px;
  margin-bottom: 60px;
} */
.info-card .info-card-details,
.info-card .info-card-details .info-card-header {
  width: 100%;
  height: 86%;
  position: absolute;
  bottom: -100%;
  left: 0;
  padding: 0 15px;
  background: #5955a5;
  text-align: center;
}
.info-card .info-card-details::-webkit-scrollbar {
  width: 8px;
}
.info-card .info-card-details::-webkit-scrollbar-button {
  width: 8px;
  height: 0px;
}
.info-card .info-card-details::-webkit-scrollbar-track {
  background: transparent;
}
.info-card .info-card-details::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
}
.info-card .info-card-details::-webkit-scrollbar-thumb:hover {
  background: rgb(130, 130, 130);
}

.info-card .info-card-details .info-card-header {
  height: auto;
  bottom: 100%;
  padding: 10px 5px;
}
.info-card:hover .info-card-details {
  bottom: 59px;
  overflow: auto;
  padding-bottom: 25px;
}
.info-card:hover .info-card-details .info-card-header {
  position: relative;
  bottom: 0px;
  padding-top: 45px;
  padding-bottom: 25px;
}
.info-card .info-card-details .info-card-header h1,
.info-card .info-card-details .info-card-header h3 {
  color: rgb(62, 62, 62);
  font-size: 22px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 !important;
  padding: 0 !important;
}
.info-card .info-card-details .info-card-header h3 {
  color: rgb(142, 182, 52);
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px;
}

.details {
  color: white;
  margin-top: 20px;
}

tbody + thead {
  display: none;
}

#appointments tbody > tr > :nth-child(3) {
  width: 14rem;
}

#appointments tbody > tr > :nth-child(2) {
  width: 14rem;
}

#appointments tbody > tr > :nth-child(1) {
  width: 12rem;
}

.table.table-hover tbody tr:hover {
  background-color: #248de4;
  color: white;
}

.groupofcard {
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.file-card-description {
  height: 20rem;
  overflow: auto !important;
  color: #ececec;
}

.facilitorname {
  font-size: 9px;
  font-weight: 700;
  color: white;
  padding-top: 5px;
  text-align: center;
  width: 59px;
}

.bookmark {
  align-items: center;
}

#notes p {
  margin-bottom: 0px !important;
}

.dashboard_table {
  /* width: 378px; */
  margin-right: 33px;
  margin-left: 33px;
  /* border: 1px solid; */
  text-align: center;
  max-height: 31em;
  overflow: auto;
  overflow-x: hidden;
}

.dashboard_eventcard {
  max-height: 35em;
  overflow: auto;
  overflow-x: hidden;
}

.dashboard_tablecard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

#dashboard_appointmenttable thead > tr > th {
  background-color: #5955a5 !important;
  color: white;
}

.transbox {
  padding: 5px 5px;
  background-color: #ffffff;
  opacity: 0.6;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

/* ----css change for userprofile image-- */
.profile-pic {
  height: 30px;
}

.topbar ul.dropdown-user li .dw-user-box .u-img {
  height: 80px;
}

.topbar ul.dropdown-user li .dw-user-box .u-img img {
  height: 80px;
}

.topbar ul.dropdown-user li .dw-user-box .u-text {
  display: block;
}

.my-profile-image {
  height: 128px;
  width: 128px;
}
